import React from "react"
import Imgix, { Picture, Source, buildURL } from "react-imgix"

import styles from "./headerImage.module.scss"
import VideoInline from "./videoInline"

const HeaderImage = props => {
  const header16x9 = props.data.ratio_16x9[0]
  const header4x3 = props.data.ratio_4x3[0]

  if (!header16x9.url.includes(".mp4")) {
    return (
      <div className={styles.header}>
        <Picture>
          <Source
            src={header16x9.url}
            // width={header16x9.width}
            className="lazyload"
            // Full width minus the gutter until the wrapper maxes out
            sizes="(min-width: calc(1344px + 2.5rem)) 1344px, calc(100vw - 2.5rem)"
            htmlAttributes={{
              media: "(min-width: 768px)",
              alt: header16x9.altText,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
          />
          <Source
            src={header4x3.url}
            className="lazyload"
            // Mobile is always full width minus the gutter
            sizes="calc(100vw - 2.5rem)"
            htmlAttributes={{ alt: header16x9.altText }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
          />
          <Imgix
            className="lazyload"
            src={buildURL(header4x3.url, { w: 50, blur: 40 })}
            sizes="calc(100vw - 2.5rem)"
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            htmlAttributes={{
              src: buildURL(header16x9.url, { w: 50, blur: 40 }),
              alt: header16x9.altText,
            }}
          />
        </Picture>
      </div>
    )
  } else {
    const attrs = {
      src: props.data.ratio_16x9[0].url,
      type: "video/mp4",
      poster: props.data.posterImage ? props.data.posterImage[0].url : null,
    }

    return (
      <div>
        <div className={styles.coverVideo}>
          <VideoInline {...attrs} />
        </div>
        <div className={styles.mobileBackup}>
          <div className={styles.header}>
            <Picture>
              <Source
                src={header4x3.url}
                className="lazyload"
                // Mobile is always full width minus the gutter
                sizes="calc(100vw - 2.5rem)"
                htmlAttributes={{ alt: header16x9.altText }}
                attributeConfig={{
                  src: "data-src",
                  srcSet: "data-srcset",
                  sizes: "data-sizes",
                }}
              />
              <Imgix
                className="lazyload"
                src={buildURL(header4x3.url, { w: 50, blur: 40 })}
                sizes="calc(100vw - 2.5rem)"
                attributeConfig={{
                  src: "data-src",
                  srcSet: "data-srcset",
                  sizes: "data-sizes",
                }}
                htmlAttributes={{
                  src: buildURL(header16x9.url, { w: 50, blur: 40 }),
                  alt: header16x9.altText,
                }}
              />
            </Picture>
          </div>
        </div>
      </div>
    )
  }
}

export default HeaderImage
